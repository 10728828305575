import React from 'react'
import { Carousel } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const Banner = () => {
    return (
        <div>
            <Carousel>
                <Carousel.Item>
                    <div className='home-overlay'>
                        <div className='home-label'>
                            <p className='banner-subheading'>Attempting assignments made easy</p>
                            <h1 className='banner-heading'>GET HELP WITH YOUR</h1>
                            <h1 className='banner-heading'>ASSIGNMENTS</h1>
                            <Link to="/contact">
                                <button className='btn btn-banner btn-primary btn-task-action' style={{ background: 'rgb(0, 8, 255, 0.8)', borderColor: 'rgb(0, 8, 255, 0.8)' }}>Learn More</button>
                            </Link>
                        </div>
                    </div>
                    <div className='fixed-banner-bg'></div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}
