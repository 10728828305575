import React from 'react';
import { Carousel } from 'react-bootstrap';
import image3 from '../../images/service-image-2.jpg';
import image4 from '../../images/image.jpg';
import image5 from '../../images/image5.jpg';
import image6 from '../../images/home-page-cartoon.png';
import DataService from '../../services/data.service';
import { useEffect, useState } from 'react';
import { encode } from 'base-64';
import Rating from '@mui/material/Rating';

export const Content = () => {
  const [rating, setRating] = useState(4.9);

  useEffect(() => {
    getRating();
    // eslint-disable-next-line
  }, []);

  const encodedCredentials = encode(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_APPLICATION_PASSWORD}`);

  const getRating = () => {
    DataService.getRatings(encodedCredentials)
      .then((data) => {
        if (data.status === 200) {
          console.log(data);
          setRating(data.data.average);
        }
      })
  }

  return (
    <>
      <div className='home-page-info'>
        <h3 className='home-quote'>We are looking for long term relations with our clients focused on enhanced customer satisfaction</h3>
      </div>
      <div className='row g-3 specialization'>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(0, 8, 255, 0.8)" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>More than 5000+ satisfied students </span>
        </div>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(0, 8, 255, 0.8)" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>Team of 50+ qualified writers  </span>
        </div>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(0, 8, 255, 0.8)" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>More than 30,000 projects completed  </span>
        </div>
      </div>
      <div className='row g-3 specialization'>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(0, 8, 255, 0.8)" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>5 years experience  </span>
        </div>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(0, 8, 255, 0.8)" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>Free plagiarism report included  </span>
        </div>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(0, 8, 255, 0.8)" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>24* 7 customer support helpline </span>
        </div>
      </div>
      <div className='row g-3 specialization'>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(0, 8, 255, 0.8)" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>Global Assignments Assistance  </span>
        </div>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(0, 8, 255, 0.8)" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>Best pricing offered  </span>
        </div>
        <div className='col-md-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(0, 8, 255, 0.8)" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>{" "}
          <span>Focus on good quality references </span>
        </div>
      </div>
      <div className='row services-row'>
        <h3 style={{ paddingBottom: '1%', paddingTop: '5%' }}>RATED ({rating}/5)</h3>
        <div>
          <Rating
            size="large"
            precision={0.1}
            sx={{ fontSize: '2.5rem' }}
            name="read-only"
            value={rating}
            readOnly
          />
        </div>
      </div>
      <div className='row services-row'>
        <p className='home-quote'>Our aim is to help students from Australia, Canada, New Zealand, UK and India to complete their courses smoothly by attained desired grades in their academic assessments and make progress in their career. </p>
      </div>
      <Carousel>
        <Carousel.Item>
          <div className='banner-overlay'>
            <div className='slider-label1'>
              <div className='row g-3'>
                <div className='col-md-6'>
                  <img src={image6} alt='Assignment Pundit' className='fixed-banner-bg2-image align-self-center' />
                </div>
                <div className='col-md-6 align-self-center'>
                  <div style={{ textAlign: 'start', marginLeft: '1%' }}>
                    <h2 style={{ fontSize: 'clamp(1.5rem,2vw,3rem)' }}>HOW IT WORKS</h2><br />
                    <div style={{ fontSize: 'clamp(0.8rem,1.1vw,2rem)' }}>
                      <p>Send your query by email or whats app and get instant response from our experts. </p>
                      <p>Place an order  and get notified about the progress of work on regular basis. </p>
                      <p>Receive the work within fixed deadline </p>
                      <p>Get instant response from experts for any modification required </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='fixed-banner-bg2'></div>
        </Carousel.Item>
      </Carousel>
      <div className='col-12'>
        <div className='row home-page-row' >
          <div className='col-md-4'>
            <h1>HOW TO <br /> SCORE HIGH</h1><br /><br />
            <div style={{ lineHeight: '2rem' }}>
              <p>Research based</p>
              <p>Critical evaluation</p>
              <p>Include visuals</p>
              <p>Effective layout</p>
              <p>Sound academic references</p>
              <p>Use examples & illustrations</p>
            </div>
          </div>
          <div className='col-md-8'>
            <div className='row'>
              <div className='col-md-6'>
                <img src={image3} alt="Assignment Pundit" className='home-info-img' />
                <img src={image4} alt="Assignment Pundit" className='home-info-img' />
              </div>
              <div className='col-md-6'>
                <img src={image5} alt="Assignment Pundit" className='home-info-img' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
