import React from 'react'
import { Card, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import serviceBanner from '../../images/service-banner.jpg';

export const Content = () => {
  return (
    <div>
      <Carousel variant="dark">
        <Carousel.Item>
          <div className='banner-overlay'>
            <h1 className='slider-label'>What We Offer</h1>
          </div>
          <img width="100%" src={serviceBanner} alt="banner" className='header-banner' />
        </Carousel.Item>
      </Carousel>
      <div className='service-page-info'>
        <h3>IT'S TIME TO RELIEVE THE STRESS OF ASSIGNMENTS</h3>
        <p>Order with Assignment Pundit and  our experts will help you in scoring better grades </p>
      </div>

      <div className='row g-3 services-listing'>
        <h1>Our Services</h1><br /><br /><br />
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Assignment Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Case Study Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
      </div>
      <div className='row g-3 services-listing'>
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Essay Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Programming Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
      </div>
      <div className='row g-3 services-listing'>
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Dissertation Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Website Development Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
      </div>
      <div className='row g-3 services-listing'>
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Research Paper Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Powerpoint Presentation Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
      </div>
      <div className='row g-3 services-listing'>
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Thesis Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Industrial Projects Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
      </div>
      <div className='row g-3 services-listing'>
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Coursework Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
        <div className='col-md-6'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Internship Projects Help
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
      </div>
      <div className='row services-row'>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
          <Link to="/contact" className='text-decoration-none'>
            <button className='btn btn-primary btn-subscribe'>
              <span style={{fontSize:'1.2rem'}}>Order Now</span>
            </button>
          </Link>
        </div>
        <div className='col-md-4'></div>
      </div>
    </div>
  )
}
