import React, { useEffect, useRef, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    makeStyles,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { NavbarDrawer } from "./NavbarDrawer";
import navbarLogo from '../images/navbar-logo-removebg-preview.png';
import Modal from 'react-bootstrap/Modal';
import DataService from "../services/data.service";
import cookie from "js-cookie";
import swal from '@sweetalert/with-react';

const useStyles = makeStyles((theme) => ({
    navlinks: {
        display: "flex",
        justifyContent: 'end'
    },
    logo: {
        marginLeft: '3%',
        flexGrow: "1",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "black",
        fontSize: "17px",
        fontWeight: "600",
        paddingLeft: "20px",
        paddingTop: '5px',
        paddingRight: '20px',
        "&:hover": {
            color: "rgb(0, 8, 255, 0.6)",
        },
    },
}));

export const Navbar = () => {
    const auth = cookie.get('assignment-token');
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const url = (window.location.href).split('/')[3];
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    let inputRef = useRef(null);
    const form_id = process.env.REACT_APP_TAKE_ACTION_FORM_ID;
    const navigate = useNavigate();

    useEffect(() => {
        if (url === "") {
            setTimeout(() => {
                setShow(true);
            }, 10000);
        }
        // eslint-disable-next-line
    }, [])

    const fileSelectHandler = (e) => {
        setFile(e.target.files[0])
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        data.append('your_name', firstName + " " + lastName);
        data.append('your_email', email);
        data.append('MobileNumber', phone);
        data.append('Requirements', file);
        data.append('your_message', message);

        DataService.handleTakeActionForm(form_id, data)
            .then((data) => {
                console.log(data);
                if (data.data.status === "mail_sent") {
                    swal({
                        icon: "success",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Requirements Uploaded !!!</h5>
                            </div>
                        )
                    });
                }
                else {
                    swal({
                        icon: "error",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Message Not sent. Try again after sometime.</h5>
                            </div>
                        )
                    });
                }
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setMessage("");
                inputRef.current.value = "";
                setShow(false);
                setLoading(false);
            })
    }

    const handleAction = () => {
        setShow(true);
    }

    const handleClose = () => setShow(false);

    const logout = () => {
        swal({
            icon: "warning",
            buttons: ["No", "Yes"],
            dangerMode: true,
            content: (
                <div>
                    <h4>Are you sure?</h4>
                    <p>You will be logged out of application !</p>
                </div>
            )
        }).then((data) => {
            if (data) {
                cookie.remove("assignment-token");
                cookie.remove("assignment-user");
                navigate("/login");
            }
        })
    }

    return (
        <>
            <AppBar position="static" style={{ background: 'white' }}>
                <Toolbar>
                    <Typography variant="h4" className={classes.logo}>
                        <div className="site--logo" data-toggle="tooltip" data-placement="top" title="Assignment Pundit">
                            <a href="/" className="custom-logo-link" rel="home" aria-current="page">
                                <img width="125" height="110" src={navbarLogo} class="custom-logo" alt="Assignment Pundit" />
                            </a>
                        </div>
                    </Typography>
                    {isMobile ? (
                        <NavbarDrawer />
                    ) : (
                        <div className={classes.navlinks} style={{ width: '100%' }}>
                            {
                                url === "" ?
                                    <Link to="/" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        <span>Home</span>
                                    </Link>
                                    :
                                    <Link to="/" className={classes.link}>
                                        <span>Home</span>
                                    </Link>
                            }
                            {
                                url === "about" ?
                                    <Link to="/about" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        About Us
                                    </Link>
                                    :
                                    <Link to="/about" className={classes.link}>
                                        About Us
                                    </Link>
                            }
                            {
                                url === "projects" ?
                                    <Link to="/projects" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        Projects
                                    </Link>
                                    :
                                    <Link to="/projects" className={classes.link}>
                                        Projects
                                    </Link>
                            }
                            {
                                url === "services" ?
                                    <Link to="/services" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        Services
                                    </Link>
                                    :
                                    <Link to="/services" className={classes.link}>
                                        Services
                                    </Link>
                            }
                            {
                                url === "courses" ?
                                    <Link to="/courses" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        <span>Courses</span>
                                    </Link>
                                    :
                                    <Link to="/courses" className={classes.link}>
                                        <span>Courses</span>
                                    </Link>
                            }
                            {
                                url === "feedback" ?
                                    <Link to="/feedback" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        <span>Feedbacks</span>
                                    </Link>
                                    :
                                    <Link to="/feedback" className={classes.link}>
                                        <span>Feedbacks</span>
                                    </Link>
                            }
                            {
                                url === "blogs" ?
                                    <Link to="/blogs" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        <span>Blogs</span>
                                    </Link>
                                    :
                                    <Link to="/blogs" className={classes.link}>
                                        <span>Blogs</span>
                                    </Link>
                            }
                            {
                                url === "contact" ?
                                    <Link to="/contact" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        Contact Us
                                    </Link>
                                    :
                                    <Link to="/contact" className={classes.link}>
                                        Contact Us
                                    </Link>
                            }
                            {
                                url === "login" ?
                                    <>
                                        {
                                            !auth ?
                                                <Link to="/login" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                                    Login
                                                </Link>
                                                :
                                                <span className={classes.link} onClick={logout} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </span>
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            !auth ?
                                                <Link to="/login" className={classes.link}>
                                                    Login
                                                </Link>
                                                :
                                                <span className={classes.link} onClick={logout} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </span>
                                        }
                                    </>
                            }
                            <button className="btn btn-primary btn-task-action" onClick={handleAction} style={{}}>ORDER NOW</button>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Modal show={show} onHide={handleClose} size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-right"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tell Us Your Requirements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='g-3' onSubmit={handleSubmit}>
                        <div style={{ display: 'flex' }}>
                            <div className='col-md-6' style={{ width: "49%" }}>
                                <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
                                <input type="text" placeholder='First Name' id='name' className='form-control inputbox' required
                                    value={firstName} onChange={e => setFirstName(e.target.value)} />
                            </div>
                            <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}>
                                <label className='form-label'><span style={{ color: 'white' }}> *</span></label>
                                <input type="text" placeholder='Last Name' className='form-control inputbox' required
                                    value={lastName} onChange={e => setLastName(e.target.value)} />
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div className='col-md-6' style={{ width: "49%" }}><br />
                                <label className='form-label'>Phone <span style={{ color: 'red' }}> *</span></label>
                                <input type="text" placeholder='Phone' className='form-control inputbox' required
                                    value={phone} onChange={e => setPhone(e.target.value)} />
                            </div>
                            <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}><br />
                                <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
                                <input type="text" placeholder='Email' className='form-control inputbox' required
                                    value={email} onChange={e => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className='col-12'><br />
                            <label className='form-label'>Message <span style={{ color: 'red' }}> *</span></label>
                            <textarea type="text" placeholder='Comment or Message' className='form-control inputbox' style={{ minHeight: '120px' }} required
                                value={message} onChange={e => setMessage(e.target.value)} />
                        </div>
                        <div className='col-12'><br />
                            <label className='form-label'>Upload your Requirements<span style={{ color: 'red' }}> *</span></label>
                            <input type="file" id="file" className='form-control inputbox'
                                onChange={fileSelectHandler} ref={inputRef} />
                        </div>
                        <div className='row g-3'>
                            <div className='col-md-4'></div>
                            <div className='col-md-4'><br />
                                {
                                    loading ?
                                        <button className='btn btn-primary btn-task-action' style={{ width: '100%' }} disabled>
                                            {
                                                loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )
                                            }{" "}
                                            Submit
                                        </button>
                                        :
                                        <button className='btn btn-primary btn-task-action' style={{ width: '100%' }}>
                                            Submit
                                        </button>
                                }
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}