import { Interweave } from 'interweave';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap'
import Footer from '../../core/Footer'
import { Navbar } from '../../core/Navbar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';
import DataService from '../../services/data.service';

export const SpecificBlog = () => {
    const [comments, setComments] = useState([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");
    const [parent, setParent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [successShow, setSuccessShow] = useState(false);
    const [commentLoading, setCommentLoading] = useState(false);
    const [blogLoading, setBlogLoading] = useState(false);
    const [blog, setBlog] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    let blogData;

    setTimeout(() => {
        setIsCopied(false);
    }, 20000);

    const params = useParams();

    const handleClose = () => {
        setSuccessShow(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getBlog();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        document.title = "Blogs - High1Digital Consulting Pvt. Ltd."
    }, []);

    const getBlog = async () => {
        setBlogLoading(true);
        DataService.getBlog(params.blog_id)
            .then((data) => {
                if (data.data) {
                    blogData = data.data;
                    setBlog(data.data);
                    setBlogLoading(false);
                    getComments();
                }
            })
    }

    const getComments = async () => {
        setCommentLoading(true)
        let data = await fetch(blogData._links.replies[0].href + '&orderby=id&order=asc&_embed', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
        data = await data.json();
        setComments(data);
        setCommentLoading(false);
    }

    const addComment = async (e) => {
        e.preventDefault();
        setLoading(true);
        DataService.addComment(blog.id, parent, name, email, comment)
            .then((data) => {
                if (data.status === 201) {
                    setSuccessShow(true);
                }
                if (data.response?.data?.code) {
                    alert(data.response.data.message);
                }
                setName("");
                setEmail("");
                setComment("");
                setParent(0);
                setLoading(false);
            })
    }

    const handleReply = (id) => {
        setParent(id)
        document.getElementById("name").focus();
    }

    const copyLink = () => {
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setIsCopied(true);
    }

    return (
        <div>
            {
                successShow && (
                    <Snackbar
                        open={successShow}
                        autoHideDuration={5000}
                        onClose={handleClose}
                        sx={{ height: "5%" }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    >
                        <Alert
                            onClose={handleClose}
                            severity="success"
                            sx={{
                                width: '100%',
                                "& .MuiAlert-icon": {
                                    color: "white",
                                    opacity: '1'
                                }
                            }}
                            style={{
                                background: 'red',
                                color: 'white',
                                fontSize: '1rem'
                            }}
                        >
                            <b>Your comment will appear once approved.</b>
                        </Alert>
                    </Snackbar>
                )
            }
            <Navbar />
            {
                blogLoading && (
                    <div className="container-fluid text-center no-padding" style={{ padding: '10%' }}>
                        <div className="col-lg-6 m-auto">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !blogLoading && (
                    <div className='specific-blog'>
                        <div className='blog-content'>
                            {
                                blog.status === 'publish' ?
                                    <div className='blog'>
                                        {
                                            blog?._embedded['wp:featuredmedia'] ?
                                                <div className='featured-image'>
                                                    <img src={blog._embedded['wp:featuredmedia']['0'].source_url} alt="High1Digital" />
                                                </div>
                                                : ""
                                        }
                                        <div className='blog-text'>
                                            <h1 className='specific-blog-title'>
                                                <Interweave content={blog.title.rendered} />
                                            </h1>
                                            <div>
                                                <span style={{ opacity: '0.6', lineHeight: '2.5' }}><b>Spread the love</b></span><br />
                                                <LinkedinShareButton
                                                    url={window.location.href}
                                                    title="Share on LinkedIn"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(0, 127, 177)" class="bi me-2" viewBox="0 0 16 16"><title>Share on LinkedIn</title>
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                    </svg>
                                                </LinkedinShareButton>
                                                <FacebookShareButton
                                                    url={window.location.href}
                                                    title="Share on Facebook"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(59, 89, 152)" class="bi me-2" viewBox="0 0 16 16"><title>Share on Facebook</title>
                                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                                    </svg>
                                                </FacebookShareButton>
                                                <TwitterShareButton
                                                    url={window.location.href}
                                                    title="Share on Twitter "
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(0, 172, 237)" class="bi me-2" viewBox="0 0 16 16"><title>Share on Twitter</title>
                                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                    </svg>
                                                </TwitterShareButton>
                                                <WhatsappShareButton
                                                    url={window.location.href}
                                                    title="Share on WhatsApp "
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(37, 211, 102)" class="bi me-2" viewBox="0 0 16 16"><title>Share on WhatsApp</title>
                                                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                                    </svg>
                                                </WhatsappShareButton>
                                                <TelegramShareButton
                                                    url={window.location.href}
                                                    title="Share on Telegram "
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(55, 174, 226)" class="bi me-2" viewBox="0 0 16 16"><title>Share on Telegram</title>
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                                                    </svg>
                                                </TelegramShareButton>
                                                <Button variant="light" onClick={copyLink}>
                                                    {isCopied ? "Copied" : "Copy Link"}
                                                </Button>
                                            </div><br />
                                            <p className='blog-content'><Interweave content={blog.content.rendered} /></p><br />
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(0, 8, 255, 0.8)" class="bi bi-person-fill" viewBox="0 0 16 16" style={{ marginTop: '-5px' }}>
                                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                                    </svg>
                                                    {blog._embedded['author']['0'].name}
                                                </div>
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='rgb(0, 8, 255, 0.8)' style={{ marginTop: '-5px' }}>
                                                        <path d="M4.5 18q-.625 0-1.062-.448Q3 17.104 3 16.5v-11q0-.604.438-1.052Q3.875 4 4.5 4H6V2h1.5v2h5V2H14v2h1.5q.625 0 1.062.448Q17 4.896 17 5.5v11q0 .604-.438 1.052Q16.125 18 15.5 18Zm0-1.5h11V9h-11v7.5Zm0-9h11v-2h-11Zm0 0v-2 2ZM10 12q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q10.312 12 10 12Zm-3.25 0q-.312 0-.531-.219Q6 11.562 6 11.25q0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q7.062 12 6.75 12Zm6.5 0q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531-.219.219-.531.219ZM10 15q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q10.312 15 10 15Zm-3.25 0q-.312 0-.531-.219Q6 14.562 6 14.25q0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q7.062 15 6.75 15Zm6.5 0q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531-.219.219-.531.219Z" />
                                                    </svg>
                                                    <Interweave content={((new Date(blog.date)).toString()).slice(0, 16)} />
                                                </div>
                                            </div>
                                            <div style={{ textAlign: 'end' }}><br />
                                                <span className='blog-comments'>
                                                    Comments - {comments.length}
                                                </span>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                        <div>
                            {
                                commentLoading && (
                                    <div className="container-fluid text-center no-padding" style={{ padding: '10%' }}>
                                        <div className="col-lg-6 m-auto">
                                            <span className="spinner-border spinner-border-sm" style={{ color: 'red' }}></span>{" "}
                                            <span style={{ color: 'red', fontSize: '1.5rem' }}>Loading Comments...</span>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                comments.length > 0 ?
                                    <table>
                                        {
                                            comments.map((item, i) => (
                                                <>
                                                    {
                                                        (item._embedded['children'] && item.parent === 0) || item.parent === 0 ?
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className='row g-3' style={{ marginTop: '1%' }}>
                                                                        <div className='col-md-2'><br />
                                                                            <img src={item.author_avatar_urls['96']} alt="High1Digital" style={{ borderRadius: '50%', marginLeft: '10%' }} />
                                                                        </div>
                                                                        <div className='col-md-10 comment'><br />
                                                                            <span><strong>{item.author_name}</strong> says: </span><br />
                                                                            <span><Interweave content={item.content.rendered} /></span>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <div className='comment-reply'>
                                                                                    <button className='btn btn-reply' onClick={() => handleReply(item.id)}>
                                                                                        <strong>Reply</strong>
                                                                                    </button>
                                                                                </div>
                                                                                <div style={{ textAlign: 'end', fontSize: '0.85rem', marginTop: '12px' }}>{((new Date(item.date)).toLocaleString([], { hour12: true }))}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            :
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className='row g-3' style={{ marginTop: '1%', marginLeft: '5%' }}>
                                                                        <div className='col-md-2'><br />
                                                                            <img src={item.author_avatar_urls['96']} alt="High1Digital" style={{ borderRadius: '50%', marginLeft: '10%' }} />
                                                                        </div>
                                                                        <div className='col-md-10 reply-comment'><br />
                                                                            <span><strong>{item.author_name}</strong> says: </span><br />
                                                                            <span><Interweave content={item.content.rendered} /></span>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <div className='comment-reply'>
                                                                                    <button className='btn btn-reply' onClick={() => handleReply(item.id)}>
                                                                                        <strong>Reply</strong>
                                                                                    </button>
                                                                                </div>
                                                                                <div style={{ textAlign: 'end', fontSize: '0.85rem', marginTop: '12px' }}>{((new Date(item.date)).toLocaleString([], { hour12: true }))}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                    }
                                                </>
                                            ))
                                        }
                                    </table> : ""
                            }
                            <br /><br />
                            <h2>Leave a Comment</h2>
                            <form className='row g-3' onSubmit={addComment}>
                                <div className='col-md-6'>
                                    <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" className='form-control inputbox' placeholder='Name' id='name'
                                        value={name} onChange={e => setName(e.target.value)} required />
                                </div>
                                <div className='col-md-6'>
                                    <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" className='form-control inputbox' placeholder='Email' id='email'
                                        value={email} onChange={e => setEmail(e.target.value)} required />
                                </div>
                                <div className='col-12'>
                                    <label className='form-label'>Comment <span style={{ color: 'red' }}> *</span></label>
                                    <textarea type="text" className='form-control inputbox' placeholder='Comment' style={{ minHeight: '120px' }}
                                        value={comment} onChange={e => setComment(e.target.value)} required />
                                </div>
                                <div className='col-md-3'>
                                    <button className='btn btn-primary btn-subscribe' style={{ width: '100%' }}>
                                        {
                                            loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )
                                        }
                                        Post Comment
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            }
            <Footer />
        </div>
    )
}
