import React from 'react'
import { Link } from 'react-router-dom'

export const Content = () => {
    return (
        <div>
            <div style={{ textAlign: 'center', padding: '5%' }}>
                <h1 className='error-text'>404</h1>
                <h6 style={{ fontSize: '2rem' }}>Sorry we cannot find that page! Go back to home</h6><br /><br />
                <button className='btn btn-task'>
                    <Link to="/" className='text-decoration-none' style={{ color: 'white' }}>Home Page</Link>
                </button>
            </div>
        </div>
    )
}
