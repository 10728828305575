import React, { useRef, useState } from 'react';
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import DataService from '../services/data.service';
import cookie from "js-cookie";
import swal from '@sweetalert/with-react';

const useStyles = makeStyles((theme) => ({
    navlinks: {
        display: "flex",
    },
    logo: {
        marginLeft: '5%',
        flexGrow: "0.8",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        "&:hover": {
            color: "rgb(0, 8, 255, 0.6)",
        },
    },
}));

export const NavbarDrawer = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const classes = useStyles();
    const url = (window.location.href).split('/')[3];
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    let inputRef = useRef(null);
    const form_id = process.env.REACT_APP_TAKE_ACTION_FORM_ID;
    const auth = cookie.get('assignment-token');
    const navigate = useNavigate();

    const fileSelectHandler = (e) => {
        setFile(e.target.files[0])
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        data.append('your_name', firstName + " " + lastName);
        data.append('your_email', email);
        data.append('MobileNumber', phone);
        data.append('Requirements', file);
        data.append('your_message', message);

        DataService.handleTakeActionForm(form_id, data)
            .then((data) => {
                console.log(data);
                if (data.data.status === "mail_sent") {
                    alert("Requirements Uploaded !!!");
                }
                else {
                    alert("Message Not sent. Try again after sometime.")
                }
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setMessage("");
                inputRef.current.value = "";
                setShow(false);
                setLoading(false);
            })
    }

    const handleAction = () => {
        setShow(true);
        setOpenDrawer(!openDrawer);
    }

    const handleClose = () => setShow(false);

    const logout = () => {
        swal({
            icon: "warning",
            buttons: ["No", "Yes"],
            dangerMode: true,
            content: (
                <div>
                    <h4>Are you sure?</h4>
                    <p>You will be logged out of application !</p>
                </div>
            )
        }).then((data) => {
            if (data) {
                cookie.remove("assignment-token");
                cookie.remove("assignment-user");
                navigate("/login");
            }
        })
    }

    return (
        <>
            {/*<nav className="navbar navbar-light top-banner" >
                <div className='top-banner-content'>
                    <p>Talk with a Specialist : </p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div data-toggle="tooltip" data-placement="top" title="Phone" onClick={() => { window.location.href = 'tel:9999999999'; }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi me-2 banner-logo" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <span>+91 9999999999</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div data-toggle="tooltip" data-placement="top" title="Whatsapp">
                        <a className="text-decoration-none" href="https://wa.me/9999999999" style={{ color: 'black' }} target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi me-2 banner-logo" viewBox="0 0 16 16">
                                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                            </svg>
                            <span>+91 9999999999</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </a>
                    </div>
                    <div data-toggle="tooltip" data-placement="top" title="Email" onClick={() => { window.location.href = 'mailto:sales@high1digital.com'; }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi me-2 banner-logo" viewBox="0 0 16 16">
                            <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z" />
                            <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z" />
                        </svg>
                        <span>sales@high1digital.com</span>
                    </div>
                </div>
            </nav>*/}
            <div>
                <Drawer open={openDrawer}
                    onClose={() => setOpenDrawer(false)}>
                    <List style={{ padding: '10px' }}>
                        <ListItem>
                            <ListItemText>
                                {
                                    url === "" ?
                                        <Link to="/" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                            <span>Home</span>
                                        </Link>
                                        :
                                        <Link to="/" className={classes.link}>
                                            <span>Home</span>
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem >
                            <ListItemText>
                                {
                                    url === "about" ?
                                        <Link to="/about" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                            About Us
                                        </Link>
                                        :
                                        <Link to="/about" className={classes.link}>
                                            About Us
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                {
                                    url === "projects" ?
                                        <Link to="/projects" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                            Projects
                                        </Link>
                                        :
                                        <Link to="/projects" className={classes.link}>
                                            Projects
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                {
                                    url === "services" ?
                                        <Link to="/services" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                            Services
                                        </Link>
                                        :
                                        <Link to="/services" className={classes.link}>
                                            Services
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                {
                                    url === "courses" ?
                                        <Link to="/courses" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                            <span>Courses</span>
                                        </Link>
                                        :
                                        <Link to="/courses" className={classes.link}>
                                            <span>Courses</span>
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                {
                                    url === "feedback" ?
                                        <Link to="/feedback" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                            <span>Feedbacks</span>
                                        </Link>
                                        :
                                        <Link to="/feedback" className={classes.link}>
                                            <span>Feedbacks</span>
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem >
                            <ListItemText>
                                {
                                    url === "blogs" ?
                                        <Link to="/blogs" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                            Blogs
                                        </Link>
                                        :
                                        <Link to="/blogs" className={classes.link}>
                                            Blogs
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem >
                            <ListItemText>
                                {
                                    url === "contact" ?
                                        <Link to="/contact" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                            Contact Us
                                        </Link>
                                        :
                                        <Link to="/contact" className={classes.link}>
                                            Contact Us
                                        </Link>
                                }
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                {
                                    url === "login" ?
                                        <>
                                            {
                                                !auth ?
                                                    <Link to="/login" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                                        Login
                                                    </Link>
                                                    :
                                                    <span className={classes.link} onClick={logout} style={{ cursor: 'pointer' }}>
                                                        Logout
                                                    </span>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                !auth ?
                                                    <Link to="/login" className={classes.link}>
                                                        Login
                                                    </Link>
                                                    :
                                                    <span className={classes.link} onClick={logout} style={{ cursor: 'pointer' }}>
                                                        Logout
                                                    </span>
                                            }
                                        </>
                                }
                            </ListItemText>
                        </ListItem>
                        <button className="btn btn-primary btn-task-action" onClick={handleAction} style={{ marginTop: '10px', width: '100%' }}>Order Now</button>
                    </List>
                </Drawer>
                <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                    <MenuIcon />
                </IconButton>
            </div>
            <Modal show={show} onHide={handleClose} size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tell Us Your Requirements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='g-3' onSubmit={handleSubmit}>
                        <div style={{ display: 'flex' }}>
                            <div className='col-md-6' style={{ width: "49%" }}>
                                <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
                                <input type="text" placeholder='First Name' id='name' className='form-control inputbox' required
                                    value={firstName} onChange={e => setFirstName(e.target.value)} />
                            </div>
                            <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}>
                                <label className='form-label'><span style={{ color: 'white' }}> *</span></label>
                                <input type="text" placeholder='Last Name' className='form-control inputbox' required
                                    value={lastName} onChange={e => setLastName(e.target.value)} />
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div className='col-md-6' style={{ width: "49%" }}><br />
                                <label className='form-label'>Phone <span style={{ color: 'red' }}> *</span></label>
                                <input type="text" placeholder='Phone' className='form-control inputbox' required
                                    value={phone} onChange={e => setPhone(e.target.value)} />
                            </div>
                            <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}><br />
                                <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
                                <input type="text" placeholder='Email' className='form-control inputbox' required
                                    value={email} onChange={e => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className='col-12'><br />
                            <label className='form-label'>Message <span style={{ color: 'red' }}> *</span></label>
                            <textarea type="text" placeholder='Comment or Message' className='form-control inputbox' style={{ minHeight: '120px' }} required
                                value={message} onChange={e => setMessage(e.target.value)} />
                        </div>
                        <div className='col-12'><br />
                            <label className='form-label'>Upload your Requirements<span style={{ color: 'red' }}> *</span></label>
                            <input type="file" id="file" className='form-control inputbox'
                                onChange={fileSelectHandler} ref={inputRef} />
                        </div>
                        <div className='row g-3'>
                            <div className='col-md-4'></div>
                            <div className='col-md-4'><br />
                                {
                                    loading ?
                                        <button className='btn btn-primary btn-task-action' style={{ width: '100%' }} disabled>
                                            {
                                                loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )
                                            }{" "}
                                            Submit
                                        </button>
                                        :
                                        <button className='btn btn-primary btn-task-action' style={{ width: '100%' }}>
                                            Submit
                                        </button>
                                }
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}