import React, { useEffect, useLayoutEffect } from 'react'
import { Banner } from '../core/Banner'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/home/Content'

export const Home = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Assignment Pundit"
  })

  return (
    <div>
      <Navbar />
      <Banner />
      <Content />
      <Footer />
    </div>
  )
}
