import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getBlogs = async (page) => {
    try {
        const result = await axios.get(`${API_URL}wp-json/wp/v2/posts?_embed&page=${page}`);
        return result;
    } catch (error) {
        return error;
    }
}

const getBlog = async (blog_id) => {
    try {
        const result = await axios.get(`${API_URL}index.php/wp-json/wp/v2/posts/${blog_id}?_embed`);
        return result;
    } catch (error) {
        return error;
    }
}

const getCategorisedBlogs = async (category_id) => {
    try {
        const result = await axios.get(`${API_URL}index.php/wp-json/wp/v2/posts?categories=${category_id}&_embed`);
        return result;
    } catch (error) {
        return error;
    }
}

const handleContactForm = async (form_id, data) => {
    try {
        const result = await axios.post(`${API_URL}wp-json/contact-form-7/v1/contact-forms/${form_id}/feedback`, data);
        return result;
    } catch (error) {
        return error;
    }
}

const handleTakeActionForm = async (form_id, data) => {
    try {
        const result = await axios.post(`${API_URL}wp-json/contact-form-7/v1/contact-forms/${form_id}/feedback`, data);
        return result;
    } catch (error) {
        return error;
    }
}

const handleSubscribeForm = async (form_id, data) => {
    try {
        const result = await axios.post(`${API_URL}wp-json/contact-form-7/v1/contact-forms/${form_id}/feedback`, data);
        return result;
    } catch (error) {
        return error;
    }
}

const handleAffiliates = async (name, email, phone, willPromote, fbLink, instagramLink, youtubeLink, website, status) => {
    const result = await axios.post(API_URL + "affiliates", {
        name,
        email,
        phone,
        willPromote,
        fbLink,
        instagramLink,
        youtubeLink,
        website,
        status
    })
    return result;
}

const addReview = async (data, encodedCredentials) => {
    const result = axios.post(`${API_URL}wp-json/site-reviews/v1/reviews`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${encodedCredentials}`
        }
    })
    return result;
}

const getReviews = async (encodedCredentials, page) => {
    const result = axios.get(`${API_URL}wp-json/site-reviews/v1/reviews?page=${page}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${encodedCredentials}`
        }
    })
    return result;
}

const getRatings = async (encodedCredentials) => {
    const result = axios.get(`${API_URL}wp-json/site-reviews/v1/summary`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${encodedCredentials}`
        }
    })
    return result;
}

const DataService = {
    getBlogs,
    getBlog,
    getCategorisedBlogs,
    handleContactForm,
    handleTakeActionForm,
    handleSubscribeForm,
    handleAffiliates,
    addReview,
    getReviews,
    getRatings
}

export default DataService;