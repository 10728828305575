import axios from "axios";
import cookie from "js-cookie";

const API_URL = process.env.REACT_APP_NODE_API_URL;

const register = async (firstName, lastName, email, phone, password, referral) => {
    const result = await axios.post(API_URL + "register", {
        name: firstName + " " + lastName,
        email: email,
        phone: phone,
        password: password,
        referral: referral
    });
    if (result.data.token) {
        cookie.set("assignment-token", result.data.token, { expires: 3, secure: true, sameSite: 'Lax' });
    }
    return result;
};

const login = async (email, password) => {
    const result = await axios.post(API_URL + "login", {
        email: email,
        password: password
    });
    if (result.data.token) {
        cookie.set("assignment-token", result.data.token, { expires: 3, secure: true, sameSite: 'Lax' });
        cookie.set("assignment-user", JSON.stringify(result.data.user[0]), { expires: 1, secure: true, sameSite: 'Lax' });
    }
    return result;
}

const AuthService = {
    register,
    login
}

export default AuthService;