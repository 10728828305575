import React from 'react'
import { Card, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import pricing from '../../images/pricing.jpg';

export const Content = () => {
    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='banner-overlay'>
                        <h1 className='slider-label'>Courses</h1>
                    </div>
                    <img width="100%" src={pricing} alt="banner" className='header-banner' />
                </Carousel.Item>
            </Carousel>
            <div className='row g-3 services-listing'>
                <h1>Courses Covered</h1><br/><br/><br/> 
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Business Management
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Commerce
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Information Technology (IT)
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
            </div>
            <div className='row g-3 services-listing'>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Social Work
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Hotel Management
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Public Health
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
            </div>
            <div className='row g-3 services-listing'>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Tourism Management
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Computer Science
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Cookery
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
            </div>
            <div className='row g-3 services-listing'>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Age Care
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Strategic Management
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Digital Marketing
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
            </div>
            <div className='row g-3 services-listing'>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Bachelor of Technology (B.Tech)
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Master of Technology (M.Tech)
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Hospitality
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
            </div>
            <div className='row g-3 services-listing'>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Economics & Finance
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Master of Business Administration (MBA)
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Master of Professional Accounting (MPA)
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
            </div>
            <div className='row g-3 services-listing'>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Human Resource Management (HRM)
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Australian Code of Ethics (ACS)
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
                <div className='col-md-4'>
                    <Link to="/contact" className='text-decoration-none'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Professional Year (PY) Course
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
            </div>
        </div>
    )
}
