import React, { useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { encode } from 'base-64';
import { Avatar, Button, Container, Dialog, DialogContent, DialogTitle, Stack, Typography, Zoom } from '@mui/material';
import DataService from '../../services/data.service';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom direction="up" ref={ref} {...props} />;
});

const labels = {
    1: 'Useless',
    2: 'Poor',
    3: 'Ok',
    4: 'Good',
    5: 'Excellent',
};

function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const Content = () => {
    const [loading, setLoading] = useState(false);
    const [userRating, setUserRating] = useState(5);
    const [hover, setHover] = useState(-1);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [rating, setRating] = useState(5);
    const [reviews, setReviews] = useState([]);
    const [page, setPage] = useState(1);
    const [length, setLength] = useState(0);
    const status = "pending";
    const is_approved = false;

    const encodedCredentials = encode(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_APPLICATION_PASSWORD}`);

    const getRating = () => {
        DataService.getRatings(encodedCredentials)
            .then((data) => {
                if (data.status === 200) {
                    console.log(data);
                    setRating(data.data.average);
                }
            })
    }

    const getAllReviews = () => {
        DataService.getReviews(encodedCredentials, page)
            .then((data) => {
                if (data.status === 200) {
                    setReviews(data.data);
                    setLength(data.data.length);
                }
            })
    }

    const increment = () => {
        if (length !== 0) {
            setPage(page + 1);
        }
    }

    const decrement = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    useEffect(() => {
        getRating();
        getAllReviews();
        // eslint-disable-next-line
    }, [page]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addReview = (e) => {
        e.preventDefault();

        if (userRating === null) {
            alert("Rating cannot be null");
            return false;
        }
        setLoading(true);

        const data = new FormData();
        data.append('rating', userRating);
        data.append('title', title);
        data.append('content', content);
        data.append('name', name);
        data.append('email', email);
        data.append('status', status);
        data.append('is_approved', is_approved);

        DataService.addReview(data, encodedCredentials)
            .then((data) => {
                if (data.status === 201) {
                    setOpen(false);
                    setLoading(false);
                }
                else {
                    setOpen(false);
                    setLoading(false);
                }
            })
    }

    return (
        <>
            <Container>
                <Rating
                    precision={0.1}
                    sx={{ fontSize: '3rem' }}
                    name="read-only"
                    value={rating}
                    readOnly />
                <br />
                <Typography variant='h6'>
                    Rated {rating}/5 by our clients
                </Typography>

                <Stack spacing={4}
                    alignItems={"center"}
                    justifyContent="center"
                >
                    {
                        reviews.map((item) => (
                            <Stack spacing={4}
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent="start"
                                sx={{ width: '100%' }}
                            >
                                <Avatar src={item.avatar} alt={item.name} sx={{ width: 96, height: 96 }} />
                                <Box sx={{
                                    width: '100%',
                                    backgroundColor: '#d3d3d34d',
                                    borderRadius: 6
                                }}>
                                    <Stack
                                        p={4}
                                        spacing={2}
                                        alignItems={"start"}
                                    >
                                        <Typography variant='h6'>{item.title}</Typography>
                                        <Rating size='medium' name="read-only" value={item.rating} readOnly />
                                        <Typography variant='body2'>{item.content}</Typography>
                                    </Stack>
                                </Box>
                            </Stack>
                        ))
                    }
                    <Stack spacing={1}
                        direction="row"
                        alignItems="center"
                    >
                        <Stack>
                            {
                                page > 1 ?
                                    <button className='btn btn-primary btn-review' variant="contained" onClick={decrement}>Previous</button>
                                    :
                                    <button className='btn btn-primary btn-review' variant="contained" disabled>Previous</button>
                            }
                        </Stack>
                        <Stack>
                            {
                                length < 10 ?
                                    <button className='btn btn-primary btn-review' variant="contained" disabled>Next</button>
                                    :
                                    <button className='btn btn-primary btn-review' variant="contained" onClick={increment}>Next</button>
                            }
                        </Stack>
                    </Stack>
                </Stack>
                <br />
                <br />

                <Button className='btn-review' variant="contained" onClick={handleClickOpen}>
                    <Typography variant='body1'>
                        Write your own Review
                    </Typography>
                </Button>
            </Container>

            <Dialog fullWidth maxWidth="sm"
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Typography variant='h5'>Add review</Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack p={2}
                        alignItems={"center"}
                        justifyContent="center"
                    >
                        <Rating
                            sx={{ fontSize: '3rem' }}
                            name="hover-feedback"
                            value={userRating}
                            precision={1}
                            getLabelText={getLabelText}
                            onChange={(event, newValue) => {
                                setUserRating(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                                setHover(newHover);
                            }}
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                        {userRating !== null && (
                            <Box sx={{ mt: 2, height: 20 }}>
                                <Typography variant='body1'>
                                    {labels[hover !== -1 ? hover : userRating]}
                                </Typography>
                            </Box>
                        )}
                    </Stack>
                    <form className='row g-3' onSubmit={addReview}>
                        <div className='col-12'>
                            <label className='form-label'>Review Title</label>
                            <input className='form-control' type="text" placeholder="Title of your Review"
                                value={title} onChange={e => setTitle(e.target.value)} required />
                        </div>
                        <div className='col-12'>
                            <label className='form-label'>Review Content</label>
                            <textarea className='form-control' type="text" placeholder="Your review" style={{ height: '120px' }}
                                value={content} onChange={e => setContent(e.target.value)} required />
                        </div>
                        <div className='col-12'>
                            <label className='form-label'>Name</label>
                            <input className='form-control' type="text" placeholder="Name"
                                value={name} onChange={e => setName(e.target.value)} required />
                        </div>
                        <div className='col-12'>
                            <label className='form-label'>Email</label>
                            <input className='form-control' type="text" placeholder="Email"
                                value={email} onChange={e => setEmail(e.target.value)} required />
                        </div>
                        <div className='col-12'>
                            {
                                loading ?
                                    <Button type='submit' className='btn-review' disabled
                                        variant='contained'
                                        sx={{ mt: 4, width: '100%' }}
                                    >
                                        <Typography variant='button' sx={{ color: 'lightgrey' }}>
                                            <span className="spinner-border spinner-border-sm"></span>{" "}
                                            Submitting...
                                        </Typography>
                                    </Button>
                                    :
                                    <Button type='submit' className='btn-review'
                                        variant='contained'
                                        sx={{ mt: 4, width: '100%' }}
                                    >
                                        Submit
                                    </Button>
                            }
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Content;