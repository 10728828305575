import React, { useEffect, useLayoutEffect } from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/courses/Content'

export const Courses = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        document.title = "Courses Covered - High1Digital Consulting Pvt. Ltd."
    }, []);

    return (
        <div>
            <Navbar />
            <Content />
            <Footer />
        </div>
    )
}
