import React, { useEffect } from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/blogs/Content'

export const Blogs = () => {
    useEffect(() => {
        document.title = "Blogs - High1Digital Consulting Pvt. Ltd."
    }, []);

    return (
        <div>
            <Navbar />
            <Content />
            <Footer />
        </div>
    )
}
