import React, { useEffect } from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/service/Content'

export const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Services - Assignment Pundit"
    })

    return (
        <div>
            <Navbar />
            <Content />
            <Footer />
        </div>
    )
}
