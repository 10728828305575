import React, { useEffect } from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/contact/Content'

export const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Contact Us - Assignment Pundit"
  })

  return (
    <div>
      <Navbar />
      <Content />
      <Footer />
    </div>
  )
}
