import React, { useEffect } from 'react';
import projectBanner from '../../images/project-banner.jpg';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const Content = () => {
  useEffect(() => {
    const counters = document.querySelectorAll(".count");
    const speed = 200;

    counters.forEach((counter) => {
      const updateCount = () => {
        const target = parseInt(+counter.getAttribute("data-target"));
        const count = parseInt(+counter.innerText);
        const increment = Math.trunc(target / speed);

        if (count < target) {
          counter.innerText = count + increment;
          setTimeout(updateCount, 1);
        } else {
          count.innerText = target;
        }
      };
      updateCount();
    });
  }, []);

  return (
    <div>
      <Carousel variant="dark">
        <Carousel.Item>
          <div className='banner-overlay'>
            <h1 className='slider-label'>Our Projects</h1>
          </div>
          <img width="100%" src={projectBanner} alt="banner" className='header-banner' />
        </Carousel.Item>
      </Carousel>
      <p className='project-page-info'>Successfully completed more than 30,000+ projects in different streams from countries like Australia, Canada, UK, New Zealand and others.  </p>
      <br />
      <div className='container'>
        <div className='row g-3'>
          <div class="col-md-4 counter">
            <h3 data-target="9936" class="count">0</h3>
            <h6>Management</h6>
          </div>
          <div class=" col-md-4 counter">
            <h3 data-target="8928" class="count">0</h3>
            <h6>Information Technology (IT)/CSE</h6>
          </div>
          <div class="col-md-4 counter">
            <h3 data-target="6326" class="count">0</h3>
            <h6>Healthcare </h6>
          </div>
        </div>
        <div className='row g-3'>
          <div class="col-md-4 counter">
            <h3 data-target="5835" class="count">0</h3>
            <h6>Tourism and Hospitality </h6>
          </div>
          <div class="col-md-4 counter">
            <h3 data-target="7887" class="count">0</h3>
            <h6>Commerce </h6>
          </div>
          <div class="col-md-4 counter">
            <h3 data-target="4532" class="count">0</h3>
            <h6>Age Care </h6>
          </div>
        </div>
      </div>
      <h4 className='project-page-info'>and<br /> Many more...</h4>
      {/*<Link to="/projects/samples" className='text-decoration-none'>
        <button className="btn btn-primary btn-expert">
          See Samples
        </button>
      </Link><br/><br/><br/>*/}
      <Link to="/contact" className='text-decoration-none'>
        <button className="btn btn-primary btn-expert">
          Book Your Expert Now
        </button>
      </Link>
      <br /><br />
    </div>
  )
}
