import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Projects } from './pages/Projects';
import { Services } from './pages/Services';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { ScrollToTop } from './core/ScrollToTop';
import { Courses } from './pages/Courses';
import { Feedback } from './pages/Feedback';
import { Blogs } from './pages/Blogs';
import { SpecificBlog } from './sections/blogs/SpecificBlog';
import { CategorisedBlog } from './sections/blogs/CategorisedBlog';
import { PageNotfound } from './pages/PageNotFound';
import { Affiliates } from './pages/Affiliates';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { RestrictedAccess } from './privateComponent/RestrictedAccess';
import { Modal } from 'react-bootstrap';
import usePWAInstall from 'use-pwa-install';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import navbarLogo from './images/navbar-logo-removebg-preview.png';
import Reviews from './pages/Reviews';
//import { ProjectSamples } from './sections/project/ProjectSamples';
//import { PreviewDocument } from './sections/project/PreviewDocument';

function App() {
  const { isInstalled, install } = usePWAInstall();
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  const handleClick = () => {
    setShow(false);
    install();
  }

  return (
    <div className="App">
      {!isInstalled && (
        <Modal show={show} onHide={handleClose} size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {!isInstalled && (
            <Modal.Body>
              <Avatar
                variant="square"
                alt="Assignment Pundit"
                src={navbarLogo}
                sx={{ width: 200, height: 200, marginLeft: 'auto', marginRight: 'auto' }}
              />
              <button className='btn btn-primary btn-expert' onClick={handleClick} style={{ width: '100%' }}>Add to home screen</button>
            </Modal.Body>
          )}
        </Modal>
      )}
      <ScrollToTop />
      <BrowserRouter>
        <Routes>
          <Route path="/reviews" element={<Reviews />}></Route>
          <Route path="*" element={<PageNotfound />}></Route>
          <Route path='/' element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path='/blogs' element={<Blogs />}></Route>
          <Route path='/projects' element={<Projects />}></Route>
          {/*<Route path='/projects/samples' element={<ProjectSamples />}></Route>
          <Route path='/projects/preview-document' element={<PreviewDocument />}></Route>*/}
          <Route path='/services' element={<Services />}></Route>
          <Route path='/courses' element={<Courses />}></Route>
          <Route path='/feedback' element={<Feedback />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
          <Route path="/blog/:blog_title/:blog_id" element={<SpecificBlog />}></Route>
          <Route path="/blog/category/:category_name/:category_id" element={<CategorisedBlog />}></Route>
          <Route element={<RestrictedAccess />}>
            <Route path="/affiliate-partner" element={<Affiliates />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
