import React from 'react'
import { Carousel } from 'react-bootstrap';
import image2 from '../../images/about-banner.jpg';
import image1 from '../../images/about-info.jpg';

export const Content = () => {
    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='banner-overlay'>
                        <h1 className='slider-label'>BEST ONLINE TUTORING HELP</h1>
                    </div>
                    <img width="100%" src={image2} alt="banner" className='header-banner' />
                </Carousel.Item>
            </Carousel>
            <div className='row services-row'>
                <div className='col-md-6 align-self-center'>
                    <p className='project-page-info' style={{ textAlign: 'start' }}>
                        We have a team of more than 50+ dedicated writers comprising doctorates and postgraduates from different educational backgrounds including Management, IT/CSE, Commerce, Healthcare, Hospitality and Tourism, and more.
                    </p>
                    <p className='project-page-info' style={{ textAlign: 'start' }}>
                        We focus on delivering research-based and grammatically correct assessments ahead of time so that students have adequate time to review and submit them in a smooth manner.
                    </p>
                </div>
                <div className='col-md-6'>
                    <img src={image1} alt="Assignment Pundit" className='about-info-img' />
                </div>
            </div>
        </div>
    )
}
