import React, { useEffect } from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/about/Content'

export const About = () => {
  useEffect(() => {
    window.scrollTo(0,0);
    document.title = "About Us - Assignment Pundit";
  })

  return (
    <div>
      <Navbar />
      <Content />
      <Footer />
    </div>
  )
}
