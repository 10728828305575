import React, { useEffect } from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/project/Content'

export const Projects = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Projects - Assignment Pundit"
    })

    return (
        <div>
            <Navbar />
            <Content />
            <Footer />
        </div>
    )
}
