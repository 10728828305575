import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import image1 from '../../images/feedback-image1.jpeg';
import image2 from '../../images/feedback-image2.jpeg';
import image3 from '../../images/feedback-image3.jpeg';
import image4 from '../../images/feedback-image4.jpeg';
import image5 from '../../images/feedback-image5.jpeg';
import image6 from '../../images/feedback-image6.jpeg';
import image7 from '../../images/feedback-image7.jpeg';
import image8 from '../../images/feedback-image8.jpeg';
import image9 from '../../images/feedback-image9.jpeg';
import image10 from '../../images/feedback-image10.jpeg';
import image11 from '../../images/feedback-image11.jpeg';
import image12 from '../../images/feedback-image12.jpeg';
import image13 from '../../images/feedback-image13.jpg';
import image14 from '../../images/feedback-image14.jpg';
import image15 from '../../images/feedback-image15.jpg';
import image16 from '../../images/feedback-image16.jpg';
import image17 from '../../images/feedback-image17.jpg';
import image18 from '../../images/feedback-image18.jpg';
import image19 from '../../images/feedback-image19.jpg';
import image20 from '../../images/feedback-image20.jpg';
import image21 from '../../images/feedback-image21.jpg';
import image22 from '../../images/feedback-image22.jpg';
import image23 from '../../images/feedback-image23.jpg';
import image24 from '../../images/feedback-image24.jpg';
import image25 from '../../images/feedback-image25.jpg';
import image26 from '../../images/feedback-image26.jpg';
import image27 from '../../images/feedback-image27.jpg';
import image28 from '../../images/feedback-image28.jpg';
import image29 from '../../images/feedback-image29.jpg';
import image30 from '../../images/feedback-image30.jpg';
import image31 from '../../images/feedback-image31.jpg';
import image32 from '../../images/feedback-image32.jpg';
import image33 from '../../images/feedback-image33.jpg';
import image34 from '../../images/feedback-image34.jpg';
import image35 from '../../images/feedback-image35.jpg';
import image36 from '../../images/feedback-image36.jpg';
import image37 from '../../images/feedback-image37.jpg';
import image38 from '../../images/feedback-image38.jpg';
import image39 from '../../images/feedback-image39.jpg';
import image40 from '../../images/feedback-image40.jpg';
import image41 from '../../images/feedback-image41.jpg';
import image42 from '../../images/feedback-image42.jpg';
import image43 from '../../images/feedback-image43.jpg';
import image44 from '../../images/feedback-image44.jpg';
import image45 from '../../images/feedback-image45.jpg';
import image46 from '../../images/feedback-image46.jpg';
import image47 from '../../images/feedback-image47.jpg';
import image48 from '../../images/feedback-image48.jpg';
import image49 from '../../images/feedback-image49.jpg';
import image50 from '../../images/feedback-image50.jpg';
import image51 from '../../images/feedback-image51.jpg';
import image52 from '../../images/feedback-image52.jpg';
import image53 from '../../images/feedback-image53.jpg';
import image54 from '../../images/feedback-image54.jpg';
import image55 from '../../images/feedback-image55.jpg';
import image56 from '../../images/feedback-image56.jpg';
import image57 from '../../images/feedback-image57.jpg';
import $ from "jquery";
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { encode } from 'base-64';
import { Button, Container, Dialog, DialogContent, DialogTitle, Stack, Typography, Zoom } from '@mui/material';
import DataService from '../../services/data.service';
import { Interweave } from 'interweave';
import swal from '@sweetalert/with-react';
import Avatar from 'react-avatar';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom direction="up" ref={ref} {...props} />;
});

const labels = {
    1: 'Useless',
    2: 'Poor',
    3: 'Ok',
    4: 'Good',
    5: 'Excellent',
};

function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export const Content = () => {
    const [layoutShift, setlayoutShift] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 925) {
            setlayoutShift(true);
        }
        else {
            $(init);

            function init() {
                $(".carousel-control-next-icon").attr('style', 'visibility:inherit !important');
                $(".carousel-control-prev-icon").attr('style', 'visibility:inherit !important');
            }
        }
    }, []);

    const [loading, setLoading] = useState(false);
    const [userRating, setUserRating] = useState(5);
    const [hover, setHover] = useState(-1);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [rating, setRating] = useState(5);
    const [reviews, setReviews] = useState([]);
    const [page, setPage] = useState(1);
    const [length, setLength] = useState(0);
    const status = "pending";
    const is_approved = false;

    const encodedCredentials = encode(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_APPLICATION_PASSWORD}`);

    const getRating = () => {
        DataService.getRatings(encodedCredentials)
            .then((data) => {
                if (data.status === 200) {
                    //console.log(data);
                    setRating(data.data.average);
                }
            })
    }

    const getAllReviews = () => {
        DataService.getReviews(encodedCredentials, page)
            .then((data) => {
                if (data.status === 200) {
                    setReviews(data.data);
                    setLength(data.data.length);
                }
            })
    }

    const increment = () => {
        if (length !== 0) {
            setPage(page + 1);
        }
    }

    const decrement = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    useEffect(() => {
        getRating();
        getAllReviews();
        // eslint-disable-next-line
    }, [page]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addReview = (e) => {
        e.preventDefault();

        if (userRating === null) {
            alert("Rating cannot be null");
            return false;
        }
        setLoading(true);

        const data = new FormData();
        data.append('rating', userRating);
        data.append('title', title);
        data.append('content', content);
        data.append('name', name);
        data.append('email', email);
        data.append('status', status);
        data.append('is_approved', is_approved);

        DataService.addReview(data, encodedCredentials)
            .then((data) => {
                if (data.status === 201) {
                    setOpen(false);
                    setLoading(false);
                    swal({
                        icon: "success",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Your review has been submitted for approval</h5>
                            </div>
                        )
                    });
                }
                else {
                    setOpen(false);
                    setLoading(false);
                    swal({
                        icon: "warning",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Review cannot be added right now. Try again after sometime.</h5>
                            </div>
                        )
                    });
                }
                setTitle("");
                setContent("");
                setName("");
                setEmail("");
            })
    }

    return (
        <>
            <div className='container'>
                <div><br />
                    <h1 style={{ fontFamily: 'sans-serif' }}><u>CUSTOMER FEEDBACK</u></h1>
                    <p style={{ fontSize: '1.2rem' }}>Our clients have rated us <b style={{ fontSize: '1.5rem' }}>{rating}/5</b> for our services</p>
                </div>
                <Stack alignItems={"center"} sx={{ width: '100%' }}>
                    {
                        layoutShift ?
                            <Rating
                                precision={0.1}
                                sx={{ fontSize: '2rem' }}
                                name="read-only"
                                value={rating}
                                readOnly
                            />
                            :
                            <Rating
                                precision={0.1}
                                sx={{ fontSize: '3rem' }}
                                name="read-only"
                                value={rating}
                                readOnly
                            />
                    }
                </Stack>
                {
                    layoutShift ?
                        <>
                            <br /><br />
                            <Carousel variant="dark" fade touch={true}>
                                <Carousel.Item>
                                    <img src={image57} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image36} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image55} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image56} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image53} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image54} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image51} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image52} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image50} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image49} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image47} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image48} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image45} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image46} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image43} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image44} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image41} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image42} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image39} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image40} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image37} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image38} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image35} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image36} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image33} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image34} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image31} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image32} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image29} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image30} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image27} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image28} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image25} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image26} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image23} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image24} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image21} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image22} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image19} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image20} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image17} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image18} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image13} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image14} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image15} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image16} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image11} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image12} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image1} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image2} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image3} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image4} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image5} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image6} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image7} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image8} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={image9} className='project-feedback' alt="Assignment Pundit" />
                                    <img src={image10} className='project-feedback' alt="Assignment Pundit" />
                                </Carousel.Item>
                            </Carousel>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(0, 8, 255)" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                &nbsp;&nbsp;&nbsp;&nbsp;{" "} Swipe to see more {" "}&nbsp;&nbsp;&nbsp;&nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(0, 8, 255)" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                            </p>
                        </>
                        :
                        <Carousel variant="dark" fade style={{ padding: '2% 10%' }} touch={true}>
                            <Carousel.Item>
                                <img src={image55} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image56} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image57} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image52} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image53} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image54} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image51} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image50} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image49} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image47} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image46} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image48} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image44} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image45} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image43} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image40} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image41} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image42} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image37} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image38} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image39} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image34} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image35} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image36} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image31} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image32} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image33} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image28} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image29} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image30} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image25} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image26} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image27} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image22} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image23} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image24} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image19} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image20} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image21} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image13} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image14} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image15} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image16} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image17} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image18} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image10} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image11} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image12} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image1} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image2} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image3} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image4} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image5} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image6} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image7} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image8} className='project-feedback' alt="Assignment Pundit" />
                                <img src={image9} className='project-feedback' alt="Assignment Pundit" />
                            </Carousel.Item>
                        </Carousel>
                }

                <Container>
                    {
                        reviews.length > 0 ?
                            <>
                                {
                                    layoutShift ?
                                        <Typography variant='h6' sx={{ mt: 8, textDecoration: 'underline' }}>
                                            Reviews
                                        </Typography>
                                        :
                                        <Typography variant='h4' sx={{ mt: 6, textDecoration: 'underline' }}>
                                            Reviews
                                        </Typography>
                                }
                                <table>
                                    {
                                        reviews.map((item, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className='row g-3' style={{ marginTop: '1%' }}>
                                                        <div className='col-md-2'><br />
                                                            <Avatar name={item.name} round={true} style={{ width: "90px", borderRadius: '50%', justifyContent: 'center', display: 'flex', margin: 'auto' }} />
                                                        </div>

                                                        <div className='col-12 review'><br />
                                                            <Typography variant='h6'>{item.title}</Typography>
                                                            <Rating size='small' name="read-only" value={item.rating} readOnly /><br />
                                                            <Typography variant='body1'><Interweave content={item.content} /></Typography>
                                                            <Stack sx={{ mt: 4 }}
                                                                direction="row"
                                                                alignItems={"center"}
                                                                justifyContent="space-between"
                                                            >
                                                                <Typography variant='subtitle2'>{item.name}</Typography>
                                                                <Typography variant='subtitle2'>{((new Date(item.modified)).toLocaleString([], { hour12: true }))}</Typography>
                                                            </Stack>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </>
                            : ""
                    }
                </Container>
                <Stack spacing={4}
                    alignItems="center"
                    justifyContent={"center"}
                >
                    <Stack spacing={3}
                        sx={{ mt: 3 }}
                        direction="row"
                        alignItems="center"
                        justifyContent={"center"}
                    >
                        <Stack>
                            {
                                page > 1 ?
                                    <button className='btn btn-primary btn-review' variant="contained" onClick={decrement}>Previous</button>
                                    :
                                    <button className='btn btn-primary btn-review' variant="contained" disabled>Previous</button>
                            }
                        </Stack>
                        <Stack>
                            {
                                length < 10 ?
                                    <button className='btn btn-primary btn-review' variant="contained" disabled>Next</button>
                                    :
                                    <button className='btn btn-primary btn-review' variant="contained" onClick={increment}>Next</button>
                            }
                        </Stack>

                    </Stack>
                    <button className='btn btn-primary btn-review' variant="contained" onClick={handleClickOpen}>
                        <Typography variant='button'>
                            Write your own Review
                        </Typography>
                    </button>
                </Stack>
            </div><br />

            <Dialog fullWidth maxWidth="sm"
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Typography variant='h5'>Add review</Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack p={2}
                        alignItems={"center"}
                        justifyContent="center"
                    >
                        {
                            layoutShift ?
                                <Rating
                                    sx={{ fontSize: '2rem' }}
                                    name="hover-feedback"
                                    value={userRating}
                                    precision={1}
                                    getLabelText={getLabelText}
                                    onChange={(event, newValue) => {
                                        setUserRating(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                    }}
                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                />
                                :
                                <Rating
                                    sx={{ fontSize: '3rem' }}
                                    name="hover-feedback"
                                    value={userRating}
                                    precision={1}
                                    getLabelText={getLabelText}
                                    onChange={(event, newValue) => {
                                        setUserRating(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                    }}
                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                />
                        }
                        {userRating !== null && (
                            <Box sx={{ mt: 2, height: 20 }}>
                                <Typography variant='body1'>
                                    {labels[hover !== -1 ? hover : userRating]}
                                </Typography>
                            </Box>
                        )}
                    </Stack>
                    <form className='row g-3' onSubmit={addReview}>
                        <div className='col-12'>
                            <label className='form-label'>Review Title<span style={{ color: 'red' }}>*</span></label>
                            <input className='form-control' type="text" placeholder="Title of your Review"
                                value={title} onChange={e => setTitle(e.target.value)} required />
                        </div>
                        <div className='col-12'>
                            <label className='form-label'>Review Content<span style={{ color: 'red' }}>*</span></label>
                            <textarea className='form-control' type="text" placeholder="Your review" style={{ height: '120px' }}
                                value={content} onChange={e => setContent(e.target.value)} required />
                        </div>
                        <div className='col-12'>
                            <label className='form-label'>Name<span style={{ color: 'red' }}>*</span></label>
                            <input className='form-control' type="text" placeholder="Name"
                                value={name} onChange={e => setName(e.target.value)} required />
                        </div>
                        <div className='col-12'>
                            <label className='form-label'>Email<span style={{ color: 'red' }}>*</span></label>
                            <input className='form-control' type="text" placeholder="Email"
                                value={email} onChange={e => setEmail(e.target.value)} required />
                        </div>
                        <div className='col-12'>
                            {
                                loading ?
                                    <Button type='submit' className='btn-review' disabled
                                        variant='contained'
                                        sx={{ mt: 4, width: '100%' }}
                                    >
                                        <Typography variant='button' sx={{ color: 'lightgrey' }}>
                                            <span className="spinner-border spinner-border-sm"></span>{" "}
                                            Submitting...
                                        </Typography>
                                    </Button>
                                    :
                                    <Button type='submit' className='btn-review'
                                        variant='contained'
                                        sx={{ mt: 4, width: '100%' }}
                                    >
                                        Submit
                                    </Button>
                            }
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}
