import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap';
import image from '../../images/contact-banner.jpg';
import DataService from '../../services/data.service';
import swal from '@sweetalert/with-react';

export const Content = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const form_id = process.env.REACT_APP_CONTACT_FORM_ID;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append('your_name', firstName + " " + lastName);
    data.append('your_email', email);
    data.append('your_subject', subject);
    data.append('your_message', message);

    DataService.handleContactForm(form_id, data)
      .then((data) => {
        if (data.data.status === "mail_sent") {
          swal({
            icon: "success",
            buttons: "OK",
            content: (
              <div><br />
                <h5>Message Sent</h5>
              </div>
            )
          });
        }
        else {
          swal({
            icon: "error",
            buttons: "OK",
            content: (
              <div><br />
                <h5>Message Not sent. Try again after sometime.</h5>
              </div>
            )
          });
        }
        setFirstName("")
        setLastName("")
        setEmail("")
        setMessage("")
        setSubject("")
        setLoading(false);
      })
  }

  return (
    <div>
      <Carousel variant="dark">
        <Carousel.Item>
          <div className='banner-overlay'>
            <h1 className='slider-label'>CONTACT US</h1>
          </div>
          <img width="100%" src={image} alt="banner" className='header-banner' />
        </Carousel.Item>
      </Carousel>
      <div className='contact-page-info'>
        <form className='row g-3' style={{ textAlign: 'start' }} onSubmit={handleSubmit}>
          <div className='col-md-4'>
            <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
            <input className='form-control inputbox' type="text" placeholder='First Name' required
              value={firstName} onChange={e => setFirstName(e.target.value)} />
          </div>
          <div className='col-md-4'>
            <label className='form-label'><span style={{ color: '#ededed' }}> *</span></label>
            <input className='form-control inputbox' type="text" placeholder='Last Name' required
              value={lastName} onChange={e => setLastName(e.target.value)} />
          </div>
          <div className='col-md-8'>
            <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
            <input className='form-control inputbox' type="text" placeholder='Email' required
              value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className='col-md-8'>
            <label className='form-label'>Subject <span style={{ color: 'red' }}> *</span></label>
            <input className='form-control inputbox' type="text" placeholder='Subject' required
              value={subject} onChange={e => setSubject(e.target.value)} />
          </div>
          <div className='col-md-12'>
            <label className='form-label'>Comment or Message <span style={{ color: 'red' }}> *</span></label>
            <textarea className='form-control inputbox' type="text" placeholder='Comment or Message' style={{ height: '150px' }} required
              value={message} onChange={e => setMessage(e.target.value)} />
          </div>
          <div className='col-md-4'><br />
            {
              loading ?
                <button className='btn btn-primary btn-contact' disabled>
                  {
                    loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )
                  }{" "}
                  Send Message
                </button>
                :
                <button className='btn btn-primary btn-contact'>
                  Send Message
                </button>
            }
          </div>
        </form>
      </div>
    </div>
  )
}
